<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }" @click="reall">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">信息展示</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div style="margin-top: 50px;margin-left: 400px;display: flex">
      <el-form-item>
        <el-tag>作业工点</el-tag>
        <el-select style="margin-left: 5px;margin-bottom: 20px;width: 200px" v-model="opername" placeholder="请选择洞口"
                   size="small" @change="getInHole">
          <el-option
              v-for="item in opernames"
              :key="item.flag"
              :label="item.operatingpoint"
              :value="item.operatingpoint"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <div style="margin-left: 100px;margin-top: 5px">
        <el-button type="primary" size="small" @click="bigshow(this.opername)">大屏展示</el-button>
        <el-button type="primary" size="small" @click="show_kjkqiti()">气体监测大屏展示</el-button>

      </div>

    </div>

    <div style="margin-left: 400px;width: 1100px">
      <vxe-table
          border
          resizable
          :align="allAlign"
          :data="allData"
          size="mini"
          highlight-hover-row
          ref="xTable1"
          :export-config="{}"
      >
        <vxe-column field="workername" title="劳工姓名" width="250"></vxe-column>
        <vxe-column field="workertel" title="劳工联系方式" width="250"></vxe-column>
        <vxe-column field="inopertime" title="进洞时间" width="250"></vxe-column>
        <vxe-column field="outopertime" title="出洞时间" width="250"></vxe-column>
        <vxe-column title="操作" width="100" show-overflow>
          <template #default="{ row }">
            <vxe-button size="mini" status="primary" @click="outoper(row)">出洞</vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Header from "@/views/header";
import {Decrypt} from "@/utils/secret";
import {MostlyCloudy} from "@element-plus/icons";
import {setNowTimesInHole} from "@/utils/time";
import {ElMessage} from "element-plus";
export default {
  name: "worker_show",
  components: {MostlyCloudy, Header},
  data(){
    return{
      opernames: [],
      opername: '',
      allAlign: 'center',
      allData: [],
      list: []
    }
  },
  computed: {
    ...mapGetters(['GET_BDST']),
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_USERID']),
  },
  created() {
    this.axios.post('/sink/getoper', (response) => {
      response.obj.forEach((item, i) => {
        item.forEach((item1, y) => {
          this.opernames.push(item1)
        })
      });
    }, {
      bdst: this.GET_BDST
    })
  },
  methods:{
    getInHole(){
      this.axios.post('/workers/getInNoOut', (response) => {
        this.allData = response.obj;
        for (let i = 0; i < this.allData.length; i++){
          this.allData[i].workertel = Decrypt(this.allData[i].workertel)
        }
      }, {
        opername: this.opername
      });

      this.axios.post('/workers/seamlessScroll', (response) => {
        this.list = response.obj;
        for (let i = 0; i < this.list.length; i++) {
          this.list[i].workertel = Decrypt(this.list[i].workertel)
        }
      }, {
        opername: this.opername
      });
    },
    outoper(row){
      this.axios.post('/workers/workerOut', (response) => {
        if (response.obj) {
          ElMessage({
            message: '出洞成功',
            type: 'success',
          });
          this.getInHole();
        } else {
          ElMessage.error('提交失败，请联系科信部')
        }
      }, {
        id: row.id,
        outime: setNowTimesInHole(new Date())
      })
    },
    bigshow(oper){
      if (oper === ''){
        ElMessage.error('请先选择工点');
      }else {
        sessionStorage.setItem(this.opername, JSON.stringify(this.list))
        this.$router.push({
          name:'Bigshow',
          params: {
            opername: oper
          }
        })
      }
    },
    show_kjkqiti(){
      this.$router.push({
        name:'Kjkshow',
      })
    }
  }
}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  justify-content: space-between;
}
</style>